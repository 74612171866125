<template>
	<a target="_blank" :href="'https://mahalo-app.io/publisher/654/company/1/title/null/subscription/'+abonnement.refAbonnement" class="card">
		<div class="card-content">
			<p class="title is-6">[{{abonnement.titre}}] {{abonnement.designation}}
				<span v-if="abonnement['en-cours']" title="Abonnement en cours" style="color:green">✔</span>
				<span v-else title="Abonnement terminé (ou à venir)" style="color:red">⏲</span>
			</p>
			<p class="subtitle is-6">
				Abonné : {{abonnement.destinataire}}
			</p>
			<p class="subtitle is-7">
				<template v-if="abonnement.details.debut">
					Du {{abonnement.details.debut}} au {{abonnement.details.fin}}
				</template>
				<template v-else>
					Du n°{{abonnement.details['premier-numero']}} au n°{{abonnement.details['dernier-numero']}}. Dernier numéro servi : {{abonnement.details['dernier-numero-servi']}}
				</template>			
			</p>
		</div>
	</a>

</template>
<script>
	export default {
		props : ['abonnement']
	}
</script>
<style lang="scss" scoped>
a {
	margin-bottom: 1em;
}
</style>